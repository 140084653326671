"use client";
import * as prismic from "@prismicio/client";

import {
  DocumentTypes,
  DocumentSliceTypes,
  Headings,
  RestrictedTags,
} from "@/utils/constants";
import { Paragraph, Excerpt, IndexItem } from "@/models/document-models";
import { Content } from "@prismicio/client";

import { parseDocumentData } from "./common-service";
import { AllDocumentTypes } from "../../prismicio-types";

export async function getRecentlyViewedDocuments(
  prismicClient: prismic.Client<AllDocumentTypes>,
  recentlyViewedDocs: any[],
) {
  const result: Content.AllDocumentTypes[] = [];
  for (let i = 0; i < recentlyViewedDocs.length; i++) {
    const recentlyViewedDoc = recentlyViewedDocs[i];
    const data = await prismicClient.getByUID(
      recentlyViewedDoc.type,
      recentlyViewedDoc.uid,
    );
    result.push(data);
  }

  return result;
}

type featuredDocList = {
  anchor: string;
  id: string;
  data: {
    meta_description?: string;
    meta_image?: {
      url: string;
    };
    article_main_title?: [];
    meta_title?: string;
  };
};

type three_up_featured_documents = {
  title: string;
  subTitle: string;
  docList: featuredDocList[];
};

type recommendedDocList = {
  title: string;
  description: string;
  url: string;
  type: string;
};

type recommendedDocuments = {
  title: string;
  subTitle: string;
  docList: recommendedDocList[];
};

export type docCenterConfigType = {
  doc_center_hero: Content.DocCenterConfigDocumentDataDocCenterHeroItem[];
  recently_viewed_docs: Content.DocCenterConfigDocumentDataRecentlyViewedDocsItem[];
  three_up_featured_documents: three_up_featured_documents;
  recommendedDocs: recommendedDocuments;
};

export async function getDocCenterConfig(
  prismicClient: prismic.Client<AllDocumentTypes>,
) {
  const uid = "doc-center-config-item";
  const docsLinkGraphQuery = `
  ... on document_center {
    article_main_title
    flyout_subhead
    meta_description
    meta_image
    meta_title
  }
  ... on document {
    article_main_title
  }`;

  const featuredDocmentGraphQuery = `three_up_featured_documents {
    headline
    subhead
  }`;

  const recommendedDocumentGraphQuery = `recommended_documents {
    headline
    subhead
    recommended_doc_1 {
      ${docsLinkGraphQuery}
    }
    recommended_doc_2 {
      ${docsLinkGraphQuery}
    }
    recommended_doc_3 {
      ${docsLinkGraphQuery}
    }
  }`;

  const featuredItemsGraphQuery = `featured_items {
    title
    description
    anchor_text
    card_image
    link {
      ${docsLinkGraphQuery}
    }
  }`;

  const graphQuery = `{
    doc_center_config {
      doc_center_hero
      recently_viewed_docs
      ${featuredItemsGraphQuery}
      ${featuredDocmentGraphQuery}
      ${recommendedDocumentGraphQuery}
    }
  }`;
  const docCenterConfigData: any = await prismicClient.getByUID(
    DocumentTypes.DocumentCenterConfig,
    uid,
    {
      graphQuery,
    },
  );

  const featuredDocsHeader =
    docCenterConfigData.data?.three_up_featured_documents[0];
  const featuredDocsData = docCenterConfigData.data?.featured_items;
  const parsedFeaturedDocs = {
    title: featuredDocsHeader?.headline,
    subTitle: featuredDocsHeader?.subhead,
    docList: featuredDocsData,
  };

  const recommendedDocsData = [
    docCenterConfigData.data?.recommended_documents[0].recommended_doc_1,
    docCenterConfigData.data.recommended_documents[0].recommended_doc_2,
    docCenterConfigData.data.recommended_documents[0].recommended_doc_3,
  ];
  const tempRecommendedDocs = recommendedDocsData.map((document) => {
    return {
      title: document.data?.article_main_title[0]?.text,
      description: document.data?.flyout_subhead,
      url: document.uid,
      type: document.type,
    };
  });
  const parsedRecommendedDocs = {
    title: docCenterConfigData.data?.recommended_documents[0].headline,
    subTitle: docCenterConfigData.data?.recommended_documents[0].subhead,
    docList: tempRecommendedDocs,
  };

  return {
    doc_center_hero: docCenterConfigData.data?.doc_center_hero,
    recently_viewed_docs: docCenterConfigData.data?.recently_viewed_docs,
    three_up_featured_documents: parsedFeaturedDocs,
    recommendedDocs: parsedRecommendedDocs,
  };
}

export async function parseIndexes(document: any) {
  let indexes: IndexItem[] = []; // [{ type: "h1", content: document.title } as IndexItem];

  for (const slice of document.slices) {
    let type = "",
      content = "";
    switch (slice.type) {
      case DocumentSliceTypes.Paragraph:
      case DocumentSliceTypes.ParagraphLegacy:
      case DocumentSliceTypes.SmallCaseParagraph:
        let paragraphLegacy: Paragraph = slice.data;
        if (
          paragraphLegacy.title?.length &&
          paragraphLegacy.title[0]?.type in Headings.dict &&
          paragraphLegacy.title[0].text.length > 0
        ) {
          type = Headings.dict["heading2"];
          content = paragraphLegacy.title[0]?.text;
        }
        break;
      case DocumentSliceTypes.CodeWalkthrough:
      case DocumentSliceTypes.VimeoEmbed:
      case DocumentSliceTypes.ImageTextBlock:
      case DocumentSliceTypes.CodeBlock:
      case DocumentSliceTypes.CodeSnippet:
      case DocumentSliceTypes.PostmanCollection:
        let postmanCollection = slice.data;
        if (postmanCollection.title) {
          type = Headings.dict["heading2"];
          content = postmanCollection.title;
        }
        break;
      case DocumentSliceTypes.Excerpt:
        let excerpt = slice.data as Excerpt;
        let excerptData = await parseIndexes(excerpt.document);
        indexes = indexes.concat(excerptData);
        continue;

      case DocumentSliceTypes.DocCenterTopic:
        let docCenterTopic = slice.data;
        docCenterTopic.items.forEach((item: any) => {
          if (item.topictitleheader) {
            indexes.push({
              type: Headings.dict["heading2"],
              content: item.topictitleheader,
            });
          }
        });
        break;
      case DocumentSliceTypes.Markdown:
        // Match all h2 from the markdown and add into the indexes
        const h2Regex = /^##\s+(.+)/gm;
        let match;
        while ((match = h2Regex.exec(slice.data.content)) !== null) {
          indexes.push({
            type: Headings.dict["heading2"],
            content: match[1],
          });
        }
        continue;
      default:
        continue;
    }
    if (type) {
      indexes.push({
        type,
        content,
      });
    }
  }
  return indexes;
}

export async function getDocCenterDocument(
  prismicClient: prismic.Client<AllDocumentTypes>,
  type: any,
  uid: string,
) {
  const data = await prismicClient.getByUID(type, uid);
  const parsedData = await parseDocumentData(prismicClient, data);
  const tempSlices = <any>[];
  parsedData?.slices.forEach((slice: any) => {
    //for excerpt and example, they contain another Document content, pulling the excerpt/example slices and adding it to the main document
    if (slice.type === "Excerpt") {
      const paragraphSlice = {
        type: DocumentSliceTypes.ParagraphLegacy,
        data: {
          title: [
            {
              type: "heading2",
              text: slice.data.document.articleMainTitle,
              spans: [],
              direction: "ltr",
            },
          ],
          text: [],
        },
      };
      tempSlices.push(paragraphSlice, ...slice.data.document.slices);
    } else {
      tempSlices.push(slice);
    }
  });
  if (parsedData) {
    parsedData.slices = tempSlices;
  }
  const pageIndex = await parseIndexes(parsedData);
  return { ...parsedData, pageIndex };
}
