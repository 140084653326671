"use client";

import { useState, useEffect, useRef, useMemo } from "react";
import {
  Box,
  Grid,
  GridItem,
  Stack,
  Link as ResourceLink,
  HStack,
} from "@/components/ChakraUiManager";
import { CardContainer } from "../../../components/common";
import DocCenterPageHeader from "../../documentcenter/doc-detail-page/doc-center-page-header/DocCenterPageHeader";
import { SliceZone } from "@prismicio/react";
import { components } from "@/slices";
import "./TutorialsDetailPage.scss";
import { fetchUrlForResources } from "@/prismic-api/common-service";
import OverviewHeader from "../overview-header/OverviewHeader";
import TutorialsFilter from "../TutorialsFilter";
import { computeDocReadTime } from "@/utils";

export default function TutorialsDetailPage(props: { home: any }) {
  const documentTitle = props.home.articleMainTitle;
  const overView = props.home.metaDescription;
  const dateString = props.home.firstPublicationDate;
  const resourceSlice = props.home.slices.filter((slice: any) => {
    return slice.type === "resources_links_section";
  });
  const additionalResources = resourceSlice.length ? resourceSlice[0].data : [];
  const [activeSection, setActiveSection] = useState<string>();
  interface PageIndexItem {
    type: string;
    content: string;
  }

  const [pageIndex, setPageIndex] = useState<PageIndexItem[]>([]);

  const pageTitleDiv = documentTitle.replaceAll(" ", "-");
  const docBodyRef = useRef<HTMLDivElement | null>(null);

  const readTime = useMemo(() => {
    if (docBodyRef.current) {
      if (docBodyRef.current) {
        return computeDocReadTime(docBodyRef.current);
      }
    }
    return 0;
    // Re-calculate time to read when inner text changes
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [docBodyRef.current?.innerText]);

  useEffect(() => {
    if (
      activeSection === undefined ||
      (null && props.home.pageIndex.length > 0)
    ) {
      // Create the new item
      const newItem = { type: "h2", content: documentTitle };

      // Add the new item to the beginning of the pageIndex array
      const newPageIndex = [newItem, ...props.home.pageIndex];
      setPageIndex(newPageIndex);
    }
  }, [pageIndex, activeSection]);

  useEffect(() => {
    if (activeSection === undefined && pageIndex?.length > 0) {
      // Set the active section to the first item's content
      setActiveSection(pageTitleDiv);
    }
  }, [pageIndex]);

  // Function to handle the activation of a link.
  const handleSetActive = (to: string) => {
    setActiveSection(to);
  };

  const scrollToDiv = (divId: string) => {
    const element = document.getElementById(divId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      handleSetActive(divId);
    }
  };

  return (
    <Stack id="doc-center-overview">
      <div id={`${pageTitleDiv}`}></div>
      <OverviewHeader
        documentTitle={documentTitle}
        overView={overView}
        breadCrumb={["Docs", "Tutorials"]}
      />
      <HStack gap={24} align="start">
        <TutorialsFilter />
        <Box className="tutorial-page" id="doc-center-page">
          <Grid templateColumns="auto 25%;">
            <GridItem className="doc-center-body">
              <CardContainer>
                <Stack gap={8} ref={docBodyRef}>
                  <DocCenterPageHeader
                    documentTitle={documentTitle}
                    dateString={dateString}
                    readTime={readTime}
                  />
                  <SliceZone
                    components={components}
                    slices={props.home.slices}
                  />
                </Stack>
              </CardContainer>
            </GridItem>
            <GridItem colSpan={1}>
              <Stack className="sticky">
                <Box className="on-this-page">
                  <div className="header">On this page</div>

                  <Stack as="nav">
                    {pageIndex &&
                      pageIndex.map((pageIndex: any, index: number) => (
                        <ResourceLink
                          key={index}
                          onClick={() => {
                            if (index === 0) {
                              // Scroll to the top of the page
                              scrollToDiv(pageTitleDiv);
                            } else {
                              // Scroll to the specified section
                              scrollToDiv(
                                pageIndex.content.replaceAll(" ", "-")
                              );
                            }
                          }}
                          style={{
                            textDecoration: "none",
                            cursor: "pointer",
                            fontWeight:
                              pageIndex.content.replaceAll(" ", "-") ===
                              activeSection
                                ? "bold"
                                : "normal",
                            backgroundColor:
                              pageIndex.content.replaceAll(" ", "-") ===
                              activeSection
                                ? "#E6F0FE"
                                : "",
                          }}
                        >
                          {pageIndex.content}
                        </ResourceLink>
                      ))}
                  </Stack>
                </Box>
                {additionalResources?.length > 0 && (
                  <Box className="additional-resources">
                    <div className="header">Additional resources</div>
                    <Stack>
                      {additionalResources.map(
                        (additionalResource: any, key: any) => {
                          return (
                            <Box className="resource-box" key={key}>
                              <ResourceLink
                                target="_blank"
                                className="app-btn-link"
                                href={fetchUrlForResources(additionalResource)}
                              >
                                {additionalResource.link_title}
                              </ResourceLink>
                            </Box>
                          );
                        }
                      )}
                    </Stack>
                  </Box>
                )}
              </Stack>
            </GridItem>
          </Grid>
        </Box>
      </HStack>
    </Stack>
  );
}
