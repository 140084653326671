"use client";

import { Box,  Text } from "@chakra-ui/react";
import "./APILibraryCopyrightFooter.scss";

type APILibraryCopyrightFooterProps = {
  isSideMenuOpen: boolean
}

const APILibraryCopyrightFooter = ({
  isSideMenuOpen
}: APILibraryCopyrightFooterProps) => {
  return (
    <Box className="footer">
      <Text className={`text ${isSideMenuOpen ? "sideMenuOpen" : ""}`}>
        Copyright © 2024 Finxact a Fiserv Company. All rights reserved.
      </Text>
    </Box>
  );
};

export default APILibraryCopyrightFooter;
