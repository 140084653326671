"use client";

import { Box, HStack } from "@/components/ChakraUiManager";
import { AppChildrenNode } from "@/models/global-models";
import "./DocumentCenter.scss";
import DocumentCenterSideMenu from "@/components/documentcenter/DocCenterSideMenu/DocumentCenterSideMenu";
import { useState, useEffect } from "react";
import { NavigationDataProvider } from "./providers";
import APILibraryCopyrightFooter from "@/components/api-library/copyright-footer/APILibraryCopyrightFooter";
import { usePathname } from "next/navigation";
import { ROUTE_BASE_URL } from "@/route-config/route-path";

export default function DocumentCenterLandingPage({
  children,
}: AppChildrenNode) {
  const pathname: any = usePathname()?.replace(/\/$/, "");
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(
    !pathname.includes(ROUTE_BASE_URL.TUTORIALS)
  );
  
  return (
    <HStack className="document-center-container">
      <NavigationDataProvider>
        <DocumentCenterSideMenu
          isSideMenuOpen={isSideMenuOpen}
          setIsSideMenuOpen={setIsSideMenuOpen}
        />
        <Box
          className={`main-content ${isSideMenuOpen ? `hide-for-mobile-device` : "full-banner"}`}
        >
          {children}
          <APILibraryCopyrightFooter isSideMenuOpen={isSideMenuOpen} />
        </Box>
      </NavigationDataProvider>
    </HStack>
  );
}
