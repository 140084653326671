"use client";

import { Content } from "@prismicio/client";
import type * as prismic from "@prismicio/client";
import { Document } from "@/models/document-models";
import { parseDocumentSlices } from "./common-service";
import { createClient } from "@/prismicio";
import { DocumentSliceTypes, DocumentTypes } from "@/utils";
import { parseIndexes } from "./document-service";
import { AllDocumentTypes } from "../../prismicio-types";

function documentDataForAPIDocument(
  documentResponse: Content.ApiLibraryDocument,
) {
  const title = documentResponse.data.api_title;
  const articleMainTitle = documentResponse.data.api_title;
  const apiFlyoutContent = documentResponse.data.api_flyout_content;
  const additionLinks = documentResponse.data.additional_links;

  return { title, articleMainTitle, apiFlyoutContent, additionLinks };
}

function documentDataForDocument(documentResponse: Content.DocumentDocument) {
  const title = documentResponse.data.article_main_title[0]?.text;
  const articleMainTitle = documentResponse.data.article_main_title[0]?.text;
  const relatedPatterns = documentResponse.data.related_patterns.map(
    (p: any) => p.related_pattern,
  );
  return { title, articleMainTitle, relatedPatterns };
}

export async function parseApiDocumentData(
  prismicClient: prismic.Client<AllDocumentTypes>,
  documentData: Content.AllDocumentTypes,
) {
  if (
    documentData.type === DocumentTypes.Document ||
    documentData.type === DocumentTypes.ApiLibrary
  ) {
    const tempDocument: Document = {
      title: "",
      type: documentData.type,
      slices: [],
      uid: documentData.uid,
      relatedPatterns: <any>[],
      additionalResources: [],
      articleMainTitle: "",
      category: "",
      firstPublicationDate: documentData.first_publication_date,
      metaDescription: "",
      readTime: 10,
      url: documentData.url || "",
      pageIndex: [],
      tags: [],
    };

    let typeSpecificData = {};
    let slices: any = [];
    switch (documentData.type) {
      case DocumentTypes.Document:
        typeSpecificData = documentDataForDocument(documentData);
        slices = documentData.data.body;
        break;
      case DocumentTypes.ApiLibrary:
        typeSpecificData = documentDataForAPIDocument(documentData);
        slices = documentData.data.slices;
        break;
    }
    tempDocument.slices = await parseDocumentSlices(prismicClient, slices);

    return { ...tempDocument, ...typeSpecificData };
  }
}

export async function getApiLibraryDocument(
  prismicClient: prismic.Client<AllDocumentTypes>,
  docType: any,
  uid: string,
) {
  const home = await prismicClient.getByUID(docType, uid);
  const data = await parseApiDocumentData(prismicClient, home);

  if (docType === DocumentTypes.Document) {
    const tempSlices = <any>[];
    data?.slices.forEach((slice: any) => {
      //for excerpt and example, they contain another Document content, pulling the excerpt/example slices and adding it to the main document
      if (slice.type === "Excerpt") {
        const paragraphSlice = {
          type: DocumentSliceTypes.ParagraphLegacy,
          data: {
            title: [
              {
                type: "heading2",
                text: slice.data.document.articleMainTitle,
                spans: [],
                direction: "ltr",
              },
            ],
            text: [],
          },
        };
        tempSlices.push(paragraphSlice, ...slice.data.document.slices);
      } else {
        tempSlices.push(slice);
      }
    });
    if (data) {
      data.slices = tempSlices;
    }
    const pageIndex = await parseIndexes(data);
    return { ...data, pageIndex };
  } else {
    return data;
  }
}

export async function getApiLibraryConfig(
  prismicClient: prismic.Client<AllDocumentTypes>,
) {
  const uid = "api-library-config";
  const apiData = await prismicClient.getByUID(
    "api_library_config_content",
    uid,
  );
  const apiWalkthrough = apiData?.data?.slices;
  const apiWelcomeData = apiData?.data?.api_library_hero?.[0];
  const apiMainContent = {
    title: apiData?.data?.main_title,
    description: apiData?.data?.main_description,
    linksTitle: apiData?.data?.next_steps_title,
    links: apiData?.data?.next_step_links,
  };
  const apiConfigData = {
    apiWelcomeData: apiWelcomeData,
    apiMainContent: apiMainContent,
    apiWalkthrough: apiWalkthrough,
  };
  return apiConfigData;
}
