"use client";

import { Box, HStack } from "@/components/ChakraUiManager";
import { AppChildrenNode } from "@/models/global-models";
import { useState } from "react";
import APILibrarySideMenu from "@/components/api-library/side-menu/APILibrarySideMenu";
import "./APILibraryLandingPage.scss";
import { useUserInfo } from "../providers";
import { Loader } from "@/components/common";
import APILibraryCopyrightFooter from "@/components/api-library/copyright-footer/APILibraryCopyrightFooter";
import { NavigationDataProvider } from "./providers";
import { usePathname } from "next/navigation";
import { ROUTE_BASE_URL } from "@/route-config/route-path";

export default function APILibraryLandingPage({ children }: AppChildrenNode) {
  const pathname: any  = usePathname()?.replace(/\/$/, "");
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(pathname !== ROUTE_BASE_URL.TUTORIALS);
  const { user } = useUserInfo();

  if (user.loading) {
    return null;
  }

  return user.loading ? (
    <Loader isOverlay />
  ) : (
    <HStack className="document-center-container">
      <NavigationDataProvider>
        {user && (
          <>
            <APILibrarySideMenu
              isSideMenuOpen={isSideMenuOpen}
              setIsSideMenuOpen={setIsSideMenuOpen}
            />
            <Box
              className={`main-content ${isSideMenuOpen ? `hide-for-mobile-device` : "full-banner"}`}
            >
              {children}

              <APILibraryCopyrightFooter isSideMenuOpen={isSideMenuOpen} />
            </Box>
          </>
        )}
      </NavigationDataProvider>
    </HStack>
  );
}
